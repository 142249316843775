import PropTypes from 'prop-types';
import React from 'react';

import Framework from '../components/framework';
import SEO from '../components/framework/seo';

const NotFoundPage = ({ location }) => {
	const header = {
		subTitle: 'The page you are looking for does not exist.',
		title: '404',
	};

	return (
		<Framework header={header} location={location}>
			<SEO location={location} title="404: Page not found" />
		</Framework>
	);
};

NotFoundPage.propTypes = {
	location: PropTypes.object.isRequired,
};

export default NotFoundPage;
